<template>
    <div>
        <div class="order_title">
             <div style="font-size: 16px; color: #1D1E1F;">订单状态</div>
             <div class="flex title_line" style="color: #8C9099; font-size: 14px">
                 <div class="el-icon-s-order" style="color: #1991FC;" v-if="form.status == 1">订单接收</div>
                 <div class="el-icon-s-order" v-else>订单接收</div>

                 <img style="width: 15px; height: 5px" v-if="form.status == 2" src="../../assets/images/yes.png" alt="">
                 <img style="width: 15px; height: 5px" v-else src="../../assets/images/no.png" alt="">
                 <div class="el-icon-suitcase" style="color: #1991FC;" v-if="form.status == 2">待发货</div>
                 <div class="el-icon-suitcase" v-else>待发货</div>
                 <img style="width: 15px; height: 5px" v-if="form.status == 3" src="../../assets/images/yes.png" alt="">
                 <img style="width: 15px; height: 5px" v-else src="../../assets/images/no.png" alt="">
                 <div class="el-icon-suitcase" style="color: #1991FC;" v-if="form.status == 3">待收货</div>
                 <div class="el-icon-suitcase" v-else>待收货</div>

<!--                  
                 <img style="width: 15px; height: 5px" src="../../assets/images/no.png" alt="">

                 <div class="el-icon-edit-outline">待验收</div> -->

                 <img style="width: 15px; height: 5px" v-if="form.status == 5" src="../../assets/images/yes.png" alt="">
                 <img style="width: 15px; height: 5px" v-else src="../../assets/images/no.png" alt="">
                 <div class="el-icon-suitcase" style="color: #1991FC;" v-if="form.status == 5">已完成</div>
                 <div class="el-icon-suitcase" v-else>已完成</div>
             </div>
        </div>
        <div style="height: 20px; background: #F6F7F9;"></div>
        <div class="order_content">
            <div style="font-size: 16px; color: #1D1E1F;">订单详情</div>

            <div class="mt16" style="font-size: 14px">
                <el-tabs v-model="tabType">
                     <el-tab-pane label="订单详情" name="1">
                         <div class="pt20 pb20 pl24 pr24" style="background: rgba(0, 145, 255, 0.02); text-align: left">
                            <div>订单状态：<span>{{statusChange(form.status)}}</span></div>
                            <div class="mt14">
                                <span>订单号：{{form.orderNo}}</span>
                                <span class="ml100 mr100">买家名称：{{form.userChineseName}}</span>
                                <span>买家账号：{{form.userName}}</span>
                                <span class="ml100">下单时间：{{form.createTime}}</span>
                            </div>
                        </div>


                        <div class="mt20">
                        <div class="sell_order_table_title ta_l flex">
                            <div style="width: 30%">商品信息</div>
                            <div style="width: 30%">SKU编码</div>
                            <div style="width: 10%">单价</div>
                            <div style="width: 10%">数量</div>
                            <div style="width: 20%">小计</div>
                        </div>

                        <div>
                            <div class="sell_order_table_content flex" v-for="(item,index) in form.orderDetails" :key="index">
                                <div class="ta_l flex" style="width: 30%">
                                    <img class="mr12" :src="item.picUrl" style="width: 70px" alt="">
                                    <div>
                                        <p style="line-height: 24px;color: #308FE5;">{{item.spuName}}</p>
                                        <p style="line-height: 24px; color: #999">{{item.skuSpecName}}：{{item.skuSpecValue}}</p>
                                    </div>
                                </div>
                                <div style="width: 30%">{{item.skuCode}}</div>
                                <div style="width: 10%">¥ {{adddh(item.skuPrice)}}</div>
                                <div style="width: 10%">{{item.quantity}}{{item.skuUnit}}</div>
                                <div style="width: 20%; color: #f00000">¥ {{adddh(item.totalPrice)}}</div>
                            </div>

                            <div class="heji mt10 ta_r">
                                <div><span>商品数量：</span><span>{{priceAll.countAll}}件</span></div>
                                <div><span>商品金额：</span><span>¥ {{adddh(priceAll.totalPriceAll)}}</span></div>
                                <div><span>运费：</span><span>¥ {{priceAll.freightPriceAll}}</span></div>
                                <div><span>应付总额：</span><span style="color: #f00000">¥ {{adddh(form.priceTotal)}}</span></div>
                                
                            </div>

                        </div>


                        <div class="flex mt20">
                            <div class="message_box">
                                <h5>收货人信息</h5>
                                <div class="mt20"><div>收货人</div><span>{{form.receiveName}}</span></div>
                                <div class="mt10 flex"><div>地址</div><span>{{form.receiveArea}} {{form.receiveAddr}}</span></div>
                                <div class="mt10"><div>手机号码</div><span>{{form.receivePhone}}</span></div>
                            </div>

                            <div class="message_box">
                                <h5>付款信息</h5>
                                <div class="mt20"><div>付款方式</div>
                                    <div>
                                        <div>微信支付</div>
                                        <div>53162563173575778</div>
                                    </div>
                                </div>
                                <!-- <div class="mt10 flex"><div>地址</div><span>北京 北京市 东城区 安定门街道 安定门东大街28号雍和大厦A座</span></div> -->
                                <div class="mt10"><div>付款时间</div><span>{{form.paymentTime}}</span></div>
                            </div>

                            <div class="message_box" v-if="form.isInvoice != 1">
                                <h5>发票信息</h5>
                                <div class="mt20"><div>发票类型</div><span>{{form.invoiceType == 1 ? '增值税专用发票' : '增值税普通发票'}}</span></div>
                                <div class="mt10 flex"><div>发票抬头</div><span>{{form.companyName}}</span></div>
                                <div class="mt10"><div>企业税号</div><span>{{form.taxpayerIdentificationNumber}}</span></div>
                            </div>
                        </div>
                    </div>
                     </el-tab-pane>
                     <el-tab-pane label="发货信息" name="2">
                         <div class="pt20 pb20 pl24 pr24" style="background: rgba(0, 145, 255, 0.02); text-align: left">
                        <div>订单状态：<span>{{statusChange(form.status)}}</span></div>
                        <div class="mt14">
                            <span>订单号：{{form.orderNo}}</span>
                            <span class="ml100 mr100">买家名称：{{form.userChineseName}}</span>
                            <span>买家账号：{{form.userName}}</span>
                            <span class="ml100">下单时间：{{form.createTime}}</span>
                        </div>
                    </div>

                    <div class="mt20">
                        <div class="sell_order_table_title ta_l flex">
                            <div style="width: 25%">商品信息</div>
                            <div style="width: 15%">SKU编码</div>
                            <div style="width: 10%">单价</div>
                            <div style="width: 8%">数量</div>
                            <div style="width: 10%">小计</div>
                            <div style="width: 12%">已发货数量</div>
                            <div style="width: 10%">发货状态</div>
                            <div style="width: 10%">订单状态</div>
                        </div>

                            <div>
                                <div class="sell_order_table_content flex" v-for="(item,index) in form.orderDetails" :key="index">
                                <div class="ta_l flex" style="width: 25%">
                                    <img class="mr12" :src="item.picUrl" style="width: 70px" alt="">
                                    <div>
                                        <p style="line-height: 24px;color: #308FE5;">{{item.spuName}}</p>
                                        <p style="line-height: 24px; color: #999">{{item.skuSpecName}}：{{item.skuSpecValue}}</p>
                                    </div>
                                </div>
                                <div style="width: 15%">{{item.skuCode}}</div>
                                <div style="width: 10%">¥ {{adddh(item.skuPrice)}}</div>
                                <div style="width: 8%">{{item.quantity}}{{item.skuUnit}}</div>
                                <div style="width: 10%; color: #f00000">¥ {{adddh(item.totalPrice)}}</div>
                                <div style="width: 12%;">{{item.isSendQuantity}}</div>
                                <div style="width: 10%;">{{item.isSendQuantity == 0 ? '未发货' : (item.isSendQuantity == item.quantity ? '全部发货' : '部分发货')}}</div>
                                <div style="width: 10%;">{{statusChange(form.status)}}</div>
                            </div>


                            </div>



                        
                        <div class="mt20" v-if="baoguoList.length > 0">
                            <h3 style="font-weight: 400; font-size: 16px">发货记录</h3>
                            <div class="mt20">
                                <el-tabs v-model="chooseBg" type="card">
                                    <el-tab-pane v-for="(item,index) in baoguoList" :key="index" :label="'包裹'+(parseInt(index)+1)" :name="parseInt(index)+1">
                                        <div class="flex" style="align-items: center">
                                            <div v-show="item.logisticsType == 2" class=" logisticsTypeCls">
                                                <div>配送方式：自送</div>
                                                <div class="ml20 mr20">配送人：{{item.sendUserName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系方式：{{item.sendUserMobile}} </div>
                                                <div>配送日期：{{item.createTime}}</div>
                                                <div class="ml20 ">备注：{{item.remark}}</div>
                                            </div>
                                            <div v-show="item.logisticsType == 1" >快递公司：{{item.kuaidiName}}</div>
                                            <div v-show="item.logisticsType == 1" class="ml20 mr20">物流单号：{{item.logisticsNumber}}</div>
                                            <div v-show="item.logisticsType == 1" >发货日期：{{item.createTime}}</div>
                                            <el-popover
                                                v-show="item.logisticsType == 1" 
                                                placement="top"
                                                width="900"
                                                @show="showWuliu(item)"
                                                trigger="hover">
                                                <div class="flex" style="height: 260px; font-size: 12px">
                                                    <div class="gundong" style="width: 660px; overflow-y: auto;">
                                                        <div v-for="(it,ix) in trackObj" :key="ix" class="flex" ><div class="pb12" style="width: 144px; flex-shrink: 0; line-height: 24px; border-right: 1px dashed #d8d8d8">{{it.time}}</div>
                                                            <div :class="ix == 0 ? 'colors' : ''" class="pr10 pl16 pb12" style="line-height: 24px">{{it.context}}</div>
                                                        </div>
                                                    </div>
                                                    <iframe :src="map" frameborder="0"></iframe>
                                                </div>
                                                <div class="el-icon-caret-bottom ml12" slot="reference" style="cursor: pointer"></div>
                                            </el-popover>
                                            
                                        </div>
 
                                        <div class="flex mt12" style="text-align: left; font-size: 12px">
                                            <div class="mr20" v-for="(itm,inx) in item.orderLogisticsDetailBOList" :key="inx">
                                                <img style="width: 60px; height: 60px" :src="itm.picUrl" alt="">
                                                <el-tooltip class="item" effect="dark" :content="itm.spuName" placement="top">
                                                    <div class="mt6 mb8" style="width: 160px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">{{itm.spuName}}</div>
                                                </el-tooltip>
                                                <!-- <div class="mt6 mb8" style="width: 160px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;"></div> -->
                                                <div>本次发货数量：{{itm.sendNumber}}</div>
                                            </div>
                                        </div>
                                    </el-tab-pane>

                                </el-tabs>
                            </div>
                        </div>
                    </div>
                     </el-tab-pane>
                     <el-tab-pane label="收款信息" name="3">
                         <div class="pt20 pb20 pl24 pr24" style="background: rgba(0, 145, 255, 0.02); text-align: left">
                            <div>订单状态：<span>{{statusChange(form.status)}}</span></div>
                            <div class="mt14">
                                <span>订单号：{{form.orderNo}}</span>
                                <span class="ml100 mr100">买家名称：{{form.userChineseName}}</span>
                                <span>买家账号：{{form.userName}}</span>
                                <span class="ml100">下单时间：{{form.createTime}}</span>
                            </div>
                        </div>


                        <div class="mt20">
                            <div class="sell_order_table_title ta_l flex">
                                <div style="width: 30%">商品信息</div>
                                <div style="width: 30%">SKU编码</div>
                                <div style="width: 10%">单价</div>
                                <div style="width: 10%">数量</div>
                                <div style="width: 20%">小计</div>
                            </div>

                            <div>
                                <div class="sell_order_table_content flex">
                                    <div class="ta_l flex" style="width: 30%">
                                        <img class="mr12" src="../../assets/images/yes.png" style="width: 70px" alt="">
                                        <div>
                                            <p style="line-height: 24px;color: #308FE5;">煤矿用钻机系 ZDY-2300矿用全液压</p>
                                            <p style="line-height: 24px; color: #999">规格：XXXXXX</p>
                                        </div>
                                    </div>
                                    <div style="width: 30%">SKU编码</div>
                                    <div style="width: 10%">单价</div>
                                    <div style="width: 10%">数量</div>
                                    <div style="width: 20%">小计</div>
                                </div>



                            </div>

                        </div>
                     </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="back-div-cls">
        </div>
        <div class="btn-box fs14 flex f_jc_c f_ai_c">
            <el-button type="primary" style="height: 32px; padding: 0 12px" @click="doBackHandler">返回</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabType: "1",
            chooseBg: 1,
            form: {},
            kuaidiList: [],
            trackObj: [],
            priceAll: {
                countAll: 0,
                totalPrice: 0,
                freightPriceAll: 0,
                totalPriceAll: 0
            },
            baoguoList: [],
            wuliu: {},
            dialogFormVisible: false
        }
    },
    mounted(){
        this.init();
        this.search();
    },
    methods: {
        init(){
            this.qa.queryUniqueObjects({dictCode: 'kuaidi100'}).then(res => {
                this.kuaidiList = res.data.dictValuePOList;
                //发货记录
                this.qa.fahuojilu({orderId: this.$route.query.id}).then(req => {
                    for(let i = 0; i < this.kuaidiList.length; i++) {
                        for(let j = 0; j < req.data.length; j++) {
                            if(this.kuaidiList[i].dictValueCode == req.data[j].logisticsCode) {
                                req.data[j].kuaidiName = this.kuaidiList[i].dictValueText;
                            }
                        }
                    }
                    this.baoguoList = req.data;
                })
            })

            
        },
        search(){
            this.qa.orderQueryById({id: this.$route.query.id}).then( res => {
                if(res.respCode == "0000") {
                    
                    for(let i = 0; i < res.data.orderDetails.length; i++) {
                        res.data.orderDetails[i].sendNumber = 0;
                        if(res.data.orderDetails[i].freightPrice == null) {
                            res.data.orderDetails[i].freightPrice = 0;
                        }
                        this.priceAll.countAll += res.data.orderDetails[i].quantity;
                        this.priceAll.totalPrice += res.data.orderDetails[i].skuPrice;
                        this.priceAll.freightPriceAll += res.data.orderDetails[i].freightPrice;
                        this.priceAll.totalPriceAll += res.data.orderDetails[i].totalPrice;
                    }
                    this.form = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        showWuliu(row){
            let params = {
                        com: row.logisticsCode,
                        num: row.logisticsNumber,
                        phone: this.form.receivePhone
                    }
                    this.qa.queryTrack(params).then(res => {
                        this.trackObj = (JSON.parse(res.data.body)).data;
                        // console.log(this.trackObj)
                    })
                    let param = {
                        com: row.logisticsCode,
                        num: row.logisticsNumber,
                        phone: this.form.receivePhone,
                        from: row.sendAddressName,
                        to: this.form.receiveArea
                    }
                    this.qa.queryMapView(param).then(res => {
                        this.map = JSON.parse(res.data.body).trailUrl;
                    })
        },
         doBackHandler(){
            this.$router.go(-1);
        },
        handleChangeAddress(e,form,thsAreaCode) {
            // this.addressForm.receiveAddress = e.join('-')
            this.wuliu.sendAddressCode = e.join('-');
            var pathLabels = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels
            this.wuliu.sendAddressName = pathLabels[0] + '/' + pathLabels[1] + '/' + pathLabels[2]
        },
        sureFahuo(){
            let params = {
                ...this.wuliu,
                orderId: this.form.id,
                logisticsType: 1,
                orderLogisticsDetailBOList: []
            };
            for(let i = 0; i < this.form.orderDetails.length; i++) {
                
                // this.form.orderDetails[i].
                if(this.form.orderDetails[i].sendNumber > 0) {
                    let obj = {};
                    obj.picUrl = this.form.orderDetails[i].picUrl;
                    obj.spuName = this.form.orderDetails[i].spuName;
                    obj.sendNumber = this.form.orderDetails[i].sendNumber;
                    obj.orderDetailId = this.form.orderDetails[i].id;
                    obj.skuId = this.form.orderDetails[i].skuId;
                    obj.spuId = this.form.orderDetails[i].spuId;

                    params.orderLogisticsDetailBOList.push(obj)
                }
            }

            this.qa.supplierOrderfahuo(params).then(res => {
                if(res.respCode == "0000") {
                     this.$message({
                        message: '发货成功！',
                        type: 'success'
                    });
                    this.init();
                    this.search();
                    this.dialogFormVisible = false;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

        tabClick(i) {
            this.tabType = i;
            // this.formInline.spuStatus = i;
            // this.search();
        },
        //列表订单状态
        statusChange(id){
            if(id == 1) {
                return '待付款'
            } else if(id == 2) {
                return '待发货'
            } else if(id == 3) {
                return '待收货'
            } else if(id == 4) {
                return '待评价'
            } else if(id == 5) {
                return '已完成'
            } else if(id == 6) {
                return '已取消'
            }
        },
        //逗号算法
        adddh(val){
            
            var str = this.highPrecisionDiv(val,10000).toFixed(2).toString();
            // console.log(str)
            var reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
            val =  str.replace(reg,"$1,");
            return val
        },
        fahuo(){
            this.dialogFormVisible = true;
        }
    }
}
</script>

<style scoped>
     div.logisticsTypeCls>div{
        float: left;
    }
    .btn-box {
        position: fixed;
        bottom: 0;
        box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.06);
        width: 100%;
        height: 50px;
        background: #fff;
        z-index: 5;
        margin-left: -24px;
    }
   div.back-div-cls{
        padding:  20px;
   }
  .order_title {
      padding: 24px;
      background: #fff;
  }
  .title_line {
      align-items: center;
      margin-top: 20px;
  }
  .title_line >img {
      margin: 0 14px;
  }
  .order_content {
      background: #fff;
      padding: 24px;
  }
  .sell_order_detail {
        background: #fff;
        padding: 24px;
    }
    h3 {
        text-align: left;
        color: #2A2727;
        font-size: 18px;
    }
    .sell_order_table_title {
        height: 40px;
        border: 1px solid #e4e4e4;
        background: #f5f5f5;
        line-height: 40px;
    }
    .sell_order_table_title >div {
        padding: 0 20px;
    }
    .sell_order_table_content {
        border: 1px solid #e4e4e4;
        border-top: 0;
    }
    .sell_order_table_content >div {
        padding: 0 20px;
        text-align: left;
        display: flex;
        align-items: center;
    }
    .heji {
        padding: 16px 20px;
        background: #FBFBFB;
        
        border: 1px solid #F1F1F1;
        color: rgba(29, 30, 31, 0.6)
    }
    .heji >div {
        text-align: right;
    }
    .heji div span {
        line-height: 32px;
    }
    .heji div span:first-child {
        display: inline-block;
        width: 80px;
        text-align: right;
        vertical-align: middle;
    }
    .heji div span:last-child {
        display: inline-block;
        width: 160px;
        text-align: right;
        font-size: 18px;
        vertical-align: middle;
        color: #1D1E1F;
    }
    .message_box {
        width: 392px;
        height: 193px;
        border: 1px solid #E4E4E4;
        text-align: left;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-right: 12px;
    }
    .message_box >div {
        display: flex;
    }
    .message_box >div >div {
        width: 84px;
        flex-shrink: 0;
        line-height: 24px;
    }
    .message_box >div >span {
        line-height: 24px;
    }
    h5 {
        font-weight: 600;
        color: #333030;
    }
    .gundong::-webkit-scrollbar {
        width: 6px;
    }
    .gundong::-webkit-scrollbar-track {
        background: #F1F1F1;
    }
    .gundong::-webkit-scrollbar-thumb {
        background: #DBDBDB;
    }
    .colors {
        color: #FCAC19;
    }
</style>